import { Button } from '@mui/material';
import { EmployeeModule } from 'Modules/CTMModules';
import { NAME_GRID } from 'common/hooks/Employee/Configuration';
import Column from 'components/DataGrid/Configurator/Column';
import { put } from 'helpers/Axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import store from 'store';
import { getConfiguration } from 'store/Employee/Configuration/Actions';

const Configurator = args => {
  const [modal, setModal] = useState(false);
  const [configuration, setConfiguration] = useState(getConfiguration(args.url));

  const toggle = () => setModal(!modal);

  const updateConfiguration = (type, data) => {
    setConfiguration({ ...configuration, ...{ [type]: data } });
  };

  const save = () => {
    put(EmployeeModule.configuration.api.item.putMyConfiguration, { name: NAME_GRID, configuration: { [args.url]: configuration } }).then(
      () => {
        store.dispatch(getConfiguration());
      },
    );
    toggle();
  };
  return (
    <>
      <div className="d-flex gap-3">
        <Button type="button" variant="contained" color="info" onClick={toggle} size="small">
          <i className="mdi mdi-cogs" />
          &nbsp;Kolumny
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Ustawienia tabeli</ModalHeader>
        <ModalBody>
          <Column {...args} updateConfiguration={updateConfiguration} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={save}>
            Zapisz
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Anuluj
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

Configurator.propTypes = {
  columns: PropTypes.array,
  url: PropTypes.string,
};

export default Configurator;
