// users
import { useAppDispatch } from '../../../../store';
import { changeDefaultWarehouse } from '../../../../store/auth/login/actions';
import useCurrentUser from '@Employee/Hooks/UseCurrentUser';
import useWarehouses from '@Warehouse/Hooks/UseWarehouses';
import user1 from 'assets/images/users/avatar-1.jpg';
import React, { FC, useState } from 'react';
//i18n
import { useTranslation } from 'react-i18next';
// Redux
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

const WarehouseDropDown: FC = () => {
  const { t } = useTranslation();
  const [warehouseMenu, setWarehouseMenu] = useState(false);
  const { isLoading: isLoadingWarehouses, data: warehouses } = useWarehouses();
  const appDispatch = useAppDispatch();

  if (isLoadingWarehouses) return <>Loading...</>;

  return (
    <Dropdown
      isOpen={warehouseMenu}
      toggle={() => setWarehouseMenu(!warehouseMenu)}
      className="d-inline-block"
      direction={window.innerWidth < 1000 ? 'down' : 'start'}
    >
      <DropdownToggle tag="span">
        <span>{t('Wybierz/zmień magazyn')}</span>
      </DropdownToggle>
      <DropdownMenu className="">
        {warehouses.map(warehouse => (
          <div
            className="dropdown-item"
            style={{ cursor: 'pointer' }}
            key={warehouse['@id']}
            onClick={() => appDispatch(changeDefaultWarehouse({ newWarehouse: warehouse['@id'] }))}
          >
            {warehouse.name}
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const ProfileMenu: FC = () => {
  const currentUser = useCurrentUser();
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user" src={currentUser?.image || user1} alt="Header Avatar" />
          <span className="d-none d-xl-inline-block ms-2 me-1">{currentUser?.email}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="dropdown-item">
            <WarehouseDropDown />
          </div>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
