import ModalLoader from '../Common/ModalLoader';
import useChatFeedSubscriber from 'Modules/Chat/Hooks/UseChatFeedSubscriber';
import useChatToasts from 'Modules/Chat/Hooks/UseChatToasts';
import RightSidebar from 'components/Theme/CommonForBoth/RightSidebar';
import Footer from 'components/Theme/VerticalLayout/Footer';
// Layout Related Components
import Header from 'components/Theme/VerticalLayout/Header';
import Sidebar from 'components/Theme/VerticalLayout/Sidebar';
import { getMobileMode } from 'helpers/ModuleUtils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Offline } from 'react-detect-offline';
//redux
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import store, { useAppSelector } from 'store';
import { fetchAppConfiguration } from 'store/AppConfiguration/Configuration/Actions';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
} from 'store/actions';

const Layout = props => {
  const [mobileMode, setMobileMode] = useState(getMobileMode());
  const dispatch = useDispatch();
  useChatFeedSubscriber();
  useChatToasts();
  const {
    AppConfiguration: { loading: appConfigurationLoading, loaded: appConfigurationLoaded },
    Layout: { leftSideBarThemeImage, layoutWidth, leftSideBarType, topbarTheme, showRightSidebar, leftSideBarTheme },
  } = useAppSelector(state => ({
    Layout: {
      leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
      leftSideBarType: state.Layout.leftSideBarType,
      layoutWidth: state.Layout.layoutWidth,
      topbarTheme: state.Layout.topbarTheme,
      showRightSidebar: state.Layout.showRightSidebar,
      leftSideBarTheme: state.Layout.leftSideBarTheme,
    },
    AppConfiguration: {
      loading: state.AppConfiguration.loading,
      loaded: state.AppConfiguration.loaded,
    },
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleMenuCallback = () => {
    if (leftSideBarType === 'default') {
      dispatch(changeSidebarType('condensed', isMobile));
    } else if (leftSideBarType === 'condensed') {
      dispatch(changeSidebarType('default', isMobile));
    }
  };

  //hides right sidebar on body click
  const hideRightbar = event => {};

  /*
  layout  settings
  */

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener('click', hideRightbar, true);

    if (!appConfigurationLoaded && !appConfigurationLoading) {
      dispatch(fetchAppConfiguration());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(store.getState());
  }, []);

  useEffect(() => {
    dispatch(changeLayout('vertical'));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  return (
    <React.Fragment>
      <div
        id="layout-wrapper"
        className={`route-${props.location.pathname
          .toLowerCase()
          .substring(1)
          .replace(/\//g, '-')
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')}`}
      >
        <Header toggleMenuCallback={toggleMenuCallback} />
        {appConfigurationLoaded && <Sidebar theme={leftSideBarTheme} type={leftSideBarType} isMobile={isMobile} />}
        <div className="main-content">
          <Offline
            polling={{
              enabled: true,
              url: `${process.env.REACT_APP_API_BASE_URL}/ping`,
              timeout: 10000,
              interval: 5000,
            }}
          >
            <ModalLoader>
              <span>Oczekiwanie na ponowne połączenie z internetem, nie odświeżaj strony aby nie utracić postępów pracy</span>
            </ModalLoader>
          </Offline>
          <div className="page-content">
            {!appConfigurationLoaded && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
            {appConfigurationLoaded && props.children}
          </div>
        </div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
