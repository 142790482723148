import { usePropertyValues } from '../Hooks/usePropertyValues';
import { Button, Tooltip } from '@mui/material';
import { FC } from 'react';

const Status: FC<{ moduleName: string; propertyName?: string; status?: number; iconOnly?: boolean }> = ({
  moduleName,
  propertyName,
  status,
  iconOnly,
}) => {
  if (!propertyName) {
    throw new Error('propertyName is required');
  }

  const { data: statuses } = usePropertyValues(moduleName, propertyName);

  if (status === null) {
    return null;
  }

  const item = statuses?.find(item => item.value === status);
  const hasColors = statuses?.some(status => status.color);
  return (
    <Tooltip title={item?.label}>
      <Button
        variant={'contained'}
        startIcon={item?.icon ? <i className={`mdi mdi-${item?.icon}`} style={{ lineHeight: 'initial', paddingLeft: 12 }}></i> : undefined}
        style={{
          padding: '3px',
          color: '#fff',
          margin: '0',
          backgroundColor: item?.color,
          width: '100%',
        }}
      >
        {!iconOnly && item?.label}
      </Button>
    </Tooltip>
  );
};

export default Status;

Status.defaultProps = {
  propertyName: 'status',
};
