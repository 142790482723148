import PriorityPicker from '@Core/Components/PriorityPicker';
import { FC, useEffect, useState } from 'react';

export const PriorityFilter: FC<{ column: any }> = ({ column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);

  useEffect(() => {
    column.setFilter(val);
  }, [val]);

  return <PriorityPicker value={val} onChange={setVal} />;
};
