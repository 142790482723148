import { EmployeeModule } from 'Modules/CTMModules';
import axios, { AxiosRequestConfig } from 'axios';
import { FieldValues } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { addSingleToast } from 'store/Toast/actions';
import { loginSuccess, logoutUser } from 'store/auth/login/actions';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
  },
});

export async function get<T = any, D = any>(url, config: AxiosRequestConfig<D> = {}): Promise<T> {
  return await axiosApi.get<T>(url, { ...config }).then(response => response.data);
}

export async function post<T = any>(url, data = {}, config = {}): Promise<T> {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => response.data);
}

export async function postRawData(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data);
}

export async function put<T = any>(url, data, config = {}): Promise<T> {
  return axiosApi.put(url, { ...data }, { ...config }).then(response => response.data);
}

export async function patch<T = any>(url, data, config = {}) {
  return axiosApi.patch<T>(url, { ...data }, { ...config }).then(response => response.data);
}

export async function del(url, config = {}) {
  return axiosApi.delete(url, { ...config }).then(response => response.data);
}

export const setUpInterceptor = store => {
  const handleError = async error => {
    if (error?.response) {
      const { status } = error.response;

      if (status === 401 && !window.location.href.includes('login') && !error.config.url.includes('/refresh-token')) {
        const originalRequest = error.config;

        if (!originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const refresh = await post(EmployeeModule.configuration.api.item.postRefreshToken, {
              refresh_token: store.getState().Login.refreshToken,
            });
            store.dispatch(loginSuccess(refresh));
            originalRequest.headers['Authorization'] = `Bearer ${refresh.token}`;
            return axiosApi(originalRequest);
          } catch (e) {
            return store.dispatch(logoutUser());
          }
        } else {
          return store.dispatch(logoutUser());
        }
      }
      if (status === 403 && !window.location.href.includes('login') && !error.config.url.includes('/refresh-token')) {
        store.dispatch(addSingleToast({ title: 'Brak uprawnień do wykonania tej akcji', config: { appearance: 'error' } }));
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  };

  axiosApi.interceptors.request.use(async config => {
    const token = store.getState().Login.token;

    if (
      token &&
      !config.url?.includes('/refresh-token') &&
      !config.url?.includes('/login_check') &&
      config.headers &&
      !config.headers?.Authorization
    ) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

  axiosApi.interceptors.response.use(response => response, handleError);
};

export type Iri = string;

export interface ApiItem {
  '@id': Iri;
  '@type': string;
}

export interface HydraView extends ApiItem {
  'hydra:first'?: string;
  'hydra:last'?: string;
  'hydra:next'?: string;
  'hydra:previous'?: string;
}

export interface ApiCollection<T extends ApiItem = ApiItem> extends ApiItem {
  'hydra:member': T[];
  'hydra:totalItems': number;
  'hydra:view': HydraView;
}

export interface ViolationInterface<T extends FieldValues = object> {
  propertyPath: FieldPath<T>;
  message: string;
  code: string | number;
}

export interface ValidationResponse<T extends FieldValues = object> {
  violations: ViolationInterface<T>[];
  'hydra:description': string;
  'hydra:title': string;
}

type EmptyObject = Record<string, unknown>;
type ApiMeta<T> = T extends ApiItem ? ApiItem : EmptyObject;
type Modify<T, R extends Partial<{ [key in keyof T]: any }>> = Omit<T, keyof R> & R;
export type Serialized<T, K extends keyof T, O extends Partial<{ [key in keyof Pick<T, K>]: any }> = object> = Modify<Pick<T, K>, O> &
  ApiMeta<T>;

function makeQueryKey(iri: string, qp: object = {}) {
  return [iri, qp];
}

interface ApiResourceOptions<TRes> extends UseQueryOptions<TRes> {
  qp?: object;
}

interface ApiResourceQuery {
  <TRes extends ApiItem>(iri: string, options?: ApiResourceOptions<TRes>): [UseQueryResult<TRes>, QueryKey];

  makeKey: (iri: string, qp?: object) => QueryKey;
}

const useApiResourceQuery: ApiResourceQuery = <T>(iri: string, { qp = {}, ...options } = {}) => {
  const key = makeQueryKey(iri, qp);
  return [
    useQuery<T>(
      key,
      async () => {
        const data = await axiosApi.get<T>(iri, {
          params: qp,
        });

        return data.data;
      },
      options,
    ),
    key,
  ];
};

useApiResourceQuery.makeKey = makeQueryKey;

export default useApiResourceQuery;
