import { ClientOrderModule } from '../CTMModules';
import StatusView from '../Core/Components/StatusView';
import ReportTrouble from '../Ecommerce/Components/Trouble/ReportTrouble';
import ViewTrouble from '../Ecommerce/Components/Trouble/ViewTrouble';
import Employee from '../Employee/Employee';
import AssemblingOrderRows from './Components/AssemblingOrder/AssemblingOrderRows';
import Boxes from './Components/AssemblingOrder/Boxes';
import RegenerateAssemblingOrder from './Components/AssemblingOrder/RegenerateAssemblingOrder';
import DeliveryMethod from '@Delivery/DeliveryMethod';
import { ClientOrder } from '@Ecommerce/ClientOrder';
import ClientOrderSource from '@Ecommerce/ClientOrderSource';
import SourceView from '@Ecommerce/Components/SourceView';
import AssemblingOrderSelection from '@Warehouse/Components/AssemblingOrder/AssemblingOrderSelection';
import Contractor from 'Modules/Contractor/Contractor';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import SelectStatusColumnFilter from 'Modules/Warehouse/Components/AssemblingOrder/SelectColumStatusFilter';
import StatusPicker from 'Modules/Warehouse/Components/AssemblingOrder/StatusPicker';
import PrintItemButton from 'components/DataGrid/Button/PrintItemButton';
import dayjs from 'dayjs';
import { createModuleColumnConfig, createPriorityColumn } from 'helpers/ModuleUtils';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';


export type AssemblingOrder = CTMRecord & {
  name?: string;
  clientOrder: null | Partial<ClientOrder>;
};

const columns: CTMListColumn<AssemblingOrder>[] = [
  createPriorityColumn<AssemblingOrder>(
    record => record.clientOrder?.priority ?? null,
    record => record.clientOrder?.['@id'],
    'clientOrder.priority',
  ),
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    minWidth: 160,
    flex: 1,
  },
  {
    id: 'clientOrder.number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'clientOrder.number',
  },
  {
    id: 'clientOrder.contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('clientOrder.contractor', Contractor),
  },
  {
    id: 'clientOrder.trader.id',
    Header: 'Handlowiec',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('clientOrder.trader', Employee),
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'warehouse-assembling-order'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: SelectStatusColumnFilter,
  },
  {
    id: 'clientOrder.orderSource.id',
    Header: 'Źródło',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('clientOrder.orderSource', ClientOrderSource),
    accessor: row =>
      typeof row.clientOrder?.orderSource !== 'string' ? <SourceView source={row.clientOrder?.orderSource ?? null} /> : null,
  },
  {
    id: 'clientOrder.deliveryMethod.id',
    Header: 'Metoda dostawy',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('clientOrder.deliveryMethod', DeliveryMethod),
    accessor: row => (typeof row.clientOrder?.deliveryMethod != 'string' ? row.clientOrder?.deliveryMethod?.relationFieldLabel : null),
  },
];

interface CustomAssemblingOrderItemPaths {
  refreshQuantities: ({ id }: { id: string | number }) => string;
}

const module: CTMModule<AssemblingOrder, CustomAssemblingOrderItemPaths> = {
  id: '721f05b9-3d03-4972-bb1c-db7d0968ac44',
  dataClass: 'CTM\\Warehouse\\Entity\\AssemblingOrder\\AssemblingOrder',
  urlPrefix: 'warehouse-assembling-order',
  name: 'Kompletacja',
  role: 'WAREHOUSE_ASSEMBLING_ORDER',
  api: {
    item: {
      get: ({ id }) => `/warehouse/assembling-orders/${id}`,
      put: ({ id }) => `/warehouse/assembling-orders/${id}`,
      delete: ({ id }) => `/warehouse/assembling-orders/${id}`,
      refreshQuantities: ({ id }) => `/warehouse/assembling-orders/${id}/refresh-quantities`,
    },
    collection: {
      get: `/warehouse/assembling-orders`,
      post: `/warehouse/assembling-orders`,
    },
  },
  recordLabel: ({ name, clientOrder }) => `Kompletacji zamówienia ${clientOrder?.number ?? ''} ${name ?? ''}`,
  form: {
    disableCreateNewRecord: true,
    prepareRecordToSave: record => ({
      ...record,
      clientOrder: record.clientOrder?.['@id'] ?? record.clientOrder,
      pallet: record.pallet?.['@id'] ?? record.pallet,
      package: record.package?.['@id'] ?? record.package,
      files: record.files?.map(el => el?.['@id'] ?? el),
      rows: record.rows?.map(el => ({
        ...el,
        product: el.product?.['@id'] ?? el.product,
        completionLogs: el.completionLogs?.map(el => ({
          ...el,
          place: el.place?.['@id'] ?? el.place,
        })),
        unit: el.unit?.['@id'] ?? el.unit,
      })),
    }),
    fieldComponents: {
      rows: AssemblingOrderRows,
      boxes: Boxes,
      status: StatusPicker,
    },
    autoSave: 4000,
    forceReadonlyField: (field, values, fields) => {
      const statusField = fields.find(el => el.propertyPath === 'status');
      if (statusField && values?.[statusField.id] >= 2500) {
        return true;
      }
      return ['costPallet', 'costHalfPallet', 'costPackagesBelow30'].includes(field.propertyPath);
    },
    contextActions: ({ readonly, record, ApiFormComponent }) => {
      return (
        <>
          {record.id && record.clientOrder && (
            <Link className={'btn btn-success ml-1'} to={ClientOrderModule.showUrl(record.clientOrder?.id ?? '')}>
              {record.clientOrder.number}
            </Link>
          )}
          {record.id && record.status < 3000 && (
            <RegenerateAssemblingOrder assemblingOrderId={record.id} ApiFormComponent={ApiFormComponent} />
          )}
          {record.id && (
            <>
              <PrintItemButton
                id={record.id}
                name={'Kompletacja'}
                downloadUrl={`/warehouse/assembling-orders/${record.id}`}
                description={'Pobierz plik PDF z kompletacją.'}
                label={true}
              />
            </>
          )}
          {record.id && <ReportTrouble clientOrderId={record.clientOrder?.id ?? ''} initialTrouble={record.clientOrder?.trouble} />}
        </>
      );
    },
    inlineComponentAppendHeader: record =>
      record.clientOrder ? <ViewTrouble clientOrderId={record.clientOrder.id} initialTrouble={record.clientOrder.trouble} /> : null,
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: true }],
    inlineComponentPrependActions: (row, listRef) => (
      <>
        {row.status < 2000 && (
          <PrintItemButton
            id={row.id}
            name={'Kompletacja'}
            downloadUrl={`/warehouse/assembling-orders/${row.id}`}
            description={'Pobierz plik PDF z kompletacją i rozpocznij.'}
            label={false}
            icon={'cursor-default-click'}
            additionalClassnames={['btn-warning']}
            parameters={{ newStatus: 2000 }}
          />
        )}
        <PrintItemButton
          id={row.id}
          name={'Kompletacja'}
          downloadUrl={`/warehouse/assembling-orders/${row.id}`}
          description={'Pobierz plik PDF z komplętacją.'}
          label={false}
        />
      </>
    ),
    storeFilters: true,
    board: true,
    boardCardOverride: ({ item, module }) => {
      return (
        <CardBody className="borad-width">
          <div>
            <h5 className="font-size-15">
              <Link to={module.showUrl(item.id)} className="text-dark">
                {item?.clientOrder?.number}
              </Link>
            </h5>
            <p className="text-muted mb-4">{item?.clientOrder?.client?.relationFieldLabel}</p>
            <p className="text-muted mb-4">{item?.clientOrder?.trader?.relationFieldLabel}</p>
          </div>
          <div className="text-end">
            <p className="mb-0 text-muted">{dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
          </div>
        </CardBody>
      );
    },
    selectionActions: AssemblingOrderSelection,
  },
};

export default module;
