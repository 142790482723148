import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ListPicker from '@Components/Form/ListPicker';
import { PriorityView } from '@Core/Components/PriorityView';
import { Priority } from '@Core/Types/Priority';

const PriorityField: CustomFieldComponentType<Priority | null | number> = ({ field, value, onChange, readonly }) => {
  return (
    <ListPicker<{ lvl: Priority }>
      name={field.name}
      label={field.name}
      value={value}
      options={[{ lvl: Priority.LOW }, { lvl: Priority.MEDIUM }, { lvl: Priority.HIGH }, { lvl: Priority.CRITICAL }]}
      labelerId={field.labelerId}
      optionLabel={item => <PriorityView priority={item.lvl} showLabel={true} />}
      optionTrackBy="lvl"
      disabled={readonly}
      errorKey={field.id}
      onChange={val => onChange?.(val, field.id)}
    />
  );
};

export default PriorityField;
