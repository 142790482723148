import { Tooltip } from '@mui/material';

interface ProductGroupProps {
  name?: string;
  path?: string;
}

const ProductGroup = ({ name, path }: ProductGroupProps) => {
  return (
    <div>
      {(path?.length ?? 0) > 0 && (
        <Tooltip title={path}>
          <span>{name}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default ProductGroup;
