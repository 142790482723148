import CheckboxColumn from '@Components/DataGrid/ColumnView/CheckboxColumn';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export const DefaultLanguageIRI = '/core/languages/pl';

export type Language = CTMRecord & {
  name: string;
  isoCode: string;
  enabled: boolean;
};

const columns: CTMListColumn<Language>[] = [
  {
    id: 'isoCode',
    filterable: false,
    sortable: false,
    Header: 'ISO',
    accessor: ({ isoCode, icon }) => (
      <>
        <span className={`fi fi-${icon}`}></span> {isoCode}
      </>
    ),
  },
  {
    id: 'name',
    filterable: false,
    sortable: false,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'active',
    filterable: false,
    sortable: false,
    Header: 'Aktywny',
    accessor: ({ enabled }) => <CheckboxColumn active={enabled} />,
  },
];

const module: CTMModule<Language> = {
  id: 'c8ba5499-9ea9-4d04-a1df-b2b76c03b5d3',
  dataClass: 'CTM\\Core\\Entity\\Language',
  urlPrefix: 'core-language',
  name: 'Języki',
  role: 'CORE_LANGUAGE',
  api: {
    item: {
      get: ({ id }) => `/core/languages/${id}`,
      put: ({ id }) => `/core/languages/${id}`,
      delete: ({ id }) => `/core/languages/${id}`,
    },
    collection: {
      get: `/core/languages`,
      post: `/core/languages`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    forceReadonlyField: (field, values, fields, recordId) => {
      return (recordId ?? '').length > 0 && field.propertyPath === 'isoCode';
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
