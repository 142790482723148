import { CustomFieldSelectorFactory } from '@Components/CustomFields/FieldTypes/CustomFieldSelector';
import CheckboxColumn from '@Components/DataGrid/ColumnView/CheckboxColumn';
import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import { Country } from '@Core/Country';
import { Field } from '@CustomFields/Field';
import BaseLinkerContextActions from '@Integration/Componenets/BaseLinkerIntegration/BaseLinkerContextActions';
import FieldMappings from '@Integration/Componenets/BaseLinkerIntegration/FieldsMappings';
import GroupMappings from '@Integration/Componenets/BaseLinkerIntegration/GroupMappings';
import StatusesMappings from '@Integration/Componenets/BaseLinkerIntegration/StatusesMappings';
import Webhooks from '@Integration/Componenets/BaseLinkerIntegration/Webhooks';
import { MultipleSalesPicker } from '@Manufacture/Components/Product/SalePicker';
import { Tariff } from '@Manufacture/Price/Tariff';
import { ProductType } from '@Manufacture/ProductType';
import { ClientOrderModule } from '@Modules/CTMModules';
import CTMModule, { CTMApiMixed, CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { post } from 'helpers/Axios';

const FieldPickerWithMultipleFilesOption = props =>
  CustomFieldSelectorFactory(props, ClientOrderModule.configuration.id, field => field.type === 'FILE' && field.config?.multiple === true);

export type BaseLinkerIntegrationPriceGroupMapping = CTMStrictRecord & {
  tariff: Tariff | null;
  vat: ValueAddedTax | null;
  deliveryCountry: Country | null;
  inventoryId: number | null;
  baseLinkerPriceGroupId: number;
  active: boolean;
  name: string;
  currency: string;
};

export type CoreBaseLinkerStatus = {
  id: number;
  name: string;
  nameForCustomer: null | string;
  color: null | string;
};

export type BaseLinkerIntegrationFieldMapping = CTMStrictRecord & {
  field: Field | string | null;
  baseLinkerPropertyPath: string;
  integration: null | string;
};

export type BaseLinkerIntegrationWebhook = CTMStrictRecord & {
  id?: string;
  baseLinkerStatusAfterSuccess: null | number;
  baseLinkerStatusAfterFailure: null | number;
  ecmStatus: null | number;
  reservationStatus: null | number;
  generateAssembling: boolean;
};

export type BaseLinkerIntegrationStatusMapping = CTMStrictRecord & {
  status: null | number;
  baseLinkerStatus: null | number;
  baseLinkerStatusData: null | CoreBaseLinkerStatus;
};

export type BaseLinkerIntegration = CTMStrictRecord & {
  id: string;
  name: string;
  token: string;
  active: boolean;
  priceGroupMappings: { [key: number]: BaseLinkerIntegrationPriceGroupMapping };
  statusMappings: { [key: number]: BaseLinkerIntegrationStatusMapping };
  fieldMappings: BaseLinkerIntegrationFieldMapping[];
  webhooks: BaseLinkerIntegrationWebhook[];
  filterProductSale: string | null;
  filterProductType: ProductType | null;
  shippingContainer: Field | string | null;
  invoicesContainer: Field | string | null;
};

const columns: CTMListColumn<BaseLinkerIntegration>[] = [
  {
    id: 'id',
    filterable: false,
    sortable: false,
    Header: 'ID',
    accessor: 'id',
  },
  {
    id: 'name',
    filterable: false,
    sortable: false,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'active',
    filterable: false,
    sortable: false,
    Header: 'Aktywny',
    accessor: ({ active }) => <CheckboxColumn active={active} />,
  },
];

type CustomActions = {
  registerActionForSynchronizeProducts: (integrationId: string) => Promise<Record<string, never>>;
  registerActionForSynchronizeProductGroups: (integrationId: string) => Promise<Record<string, never>>;
  registerActionForConnectProducts: (integrationId: string) => Promise<Record<string, never>>;
  registerActionForWipeProductsConnections: (integrationId: string) => Promise<Record<string, never>>;
  registerActionForWipeProductGroupsConnections: (integrationId: string) => Promise<Record<string, never>>;
};

const baseLinkerIntegrationCustomAPIActions: CustomActions = {
  registerActionForSynchronizeProducts: integrationId =>
    post('/core/integration/base-linker-integrations/action/synchronize-products', { integrationId }),
  registerActionForSynchronizeProductGroups: integrationId =>
    post('/core/integration/base-linker-integrations/action/synchronize-product-groups', { integrationId }),
  registerActionForConnectProducts: integrationId =>
    post('/core/integration/base-linker-integrations/action/connect-products', { integrationId }),
  registerActionForWipeProductsConnections: integrationId =>
    post('/core/integration/base-linker-integrations/action/wipe-products-connections', { integrationId }),
  registerActionForWipeProductGroupsConnections: integrationId =>
    post('/core/integration/base-linker-integrations/action/wipe-product-groups-connections', { integrationId }),
};

const module: CTMModule<BaseLinkerIntegration, CTMApiMixed, CTMApiMixed, CustomActions> = {
  id: '30ac639d-14b7-48bd-9efd-a3f57805846f',
  dataClass: 'CTM\\Integration\\BaseLinker\\Entity\\ModuleConfiguration\\BaseLinkerIntegration',
  urlPrefix: 'integration-base_linker',
  name: 'Integracja BaseLinker',
  role: 'CONFIGURATION',
  roleEdit: 'ROLE_EDIT_CONFIGURATION',
  api: {
    item: {
      get: ({ id }) => `/core/integration/base-linker-integrations/${id}`,
      put: ({ id }) => `/core/integration/base-linker-integrations/${id}`,
      delete: ({ id }) => `/core/integration/base-linker-integrations/${id}`,
    },
    collection: {
      get: `/core/integration/base-linker-integrations`,
      post: `/core/integration/base-linker-integrations`,
    },
    custom: baseLinkerIntegrationCustomAPIActions,
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      priceGroupMappings: GroupMappings,
      fieldMappings: FieldMappings,
      filterProductSale: MultipleSalesPicker,
      statusMappings: StatusesMappings,
      webhooks: Webhooks,
      invoicesContainer: props => FieldPickerWithMultipleFilesOption(props),
      shippingContainer: props => FieldPickerWithMultipleFilesOption(props),
    },
    prepareRecordToSave: record => {
      return {
        ...record,
        filterProductType: record.filterProductType?.['@id'] ?? record.filterProductType,
        priceGroupMappings: Object.values(record.priceGroupMappings ?? {}).map(mapping => ({
          ...mapping,
          tariff: mapping.tariff?.['@id'] ?? mapping.tariff,
          vat: mapping.vat?.['@id'] ?? mapping.vat,
          deliveryCountry: mapping.deliveryCountry?.['@id'] ?? mapping.deliveryCountry,
        })),
        fieldMappings: record.fieldMappings?.map(mapping => ({
          ...mapping,
          field: mapping.field?.['@id'] ?? mapping.field,
        })),
        invoicesContainer: record.invoicesContainer?.['@id'] ?? record.invoicesContainer,
        shippingContainer: record.shippingContainer?.['@id'] ?? record.shippingContainer,
      };
    },
    contextActions: ({ readonly, record }) =>
      readonly && record.active && record.id ? <BaseLinkerContextActions integrationId={record.id} /> : <></>,
  },
  list: {
    role: 'ROLE_SHOW_CONFIGURATION',
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
    closeAfterListAction: false,
  },
};

export default module;
