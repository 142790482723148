import { useAppDispatch } from '../../../../store';
import { changeDefaultWarehouse } from '../../../../store/auth/login/actions';
import useWarehouses from '@Warehouse/Hooks/UseWarehouses';
import { PanToolAlt } from '@mui/icons-material';
import { Divider, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Fragment } from 'react';

const DefaultWarehouseSelector = () => {
  const { isLoading: isLoadingWarehouses, data: warehouses } = useWarehouses();
  const appDispatch = useAppDispatch();
  if (isLoadingWarehouses) return <>Loading...</>;

  return (
    <div>
      <List>
        {warehouses.map(warehouse => (
          <Fragment key={warehouse['@id']}>
            <ListItem
              onClick={() => appDispatch(changeDefaultWarehouse({ newWarehouse: warehouse['@id'] }))}
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <PanToolAlt color="primary" />
                </IconButton>
              }
            >
              <ListItemText primary={warehouse.name} />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
          </Fragment>
        ))}
      </List>
    </div>
  );
};

export default DefaultWarehouseSelector;
