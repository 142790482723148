import TooltipField from '@Components/DataGrid/Field/TooltipField';
import { useDispatch } from 'react-redux';
import { setImages, toggle } from 'store/Lightbox/Lightbox';

interface ProductNameProps {
  name?: string;
  image?: string;
}

const ProductName = ({ name, image }: ProductNameProps) => {
  const dispatch = useDispatch<any>();

  const initPreview = (event): void => {
    if (!image) {
      return;
    }

    event.stopPropagation();

    dispatch(setImages({ images: [image] }));
    dispatch(toggle());
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          flex: 1,
          marginRight: '5px',
          height: '50px',
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          backgroundImage: `url("${image}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          padding: 2,
          border: image ? '1px solid #dfdfdf' : 'none',
          borderRadius: 1,
          overflow: 'hidden',
        }}
        onClick={initPreview}
      >
        {image && <img src={image} alt={'thumb'} style={{ height: '60px', display: '60px', opacity: 0 }} />}
      </div>
      <div>
        <TooltipField text={name || ''} trim={25} />
      </div>
    </div>
  );
};

export default ProductName;
