import * as url from '../../../helpers/Api/Url';
import { axiosApi } from '../../../helpers/Axios';
import { downloadBlobFile } from '../../../helpers/File';
import { Media } from '@Core/Types/ApiModel';

export default function useMediaDownload() {
  const download = (url: string, name: string) => {
    axiosApi
      .get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'image/*',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, name);
      });
  };

  const downloadMedia = (mediaFile: Media): void => download(url.DOWNLOAD_MEDIA(mediaFile.id), mediaFile.originalName);

  return { downloadMedia, download };
}
