import { Field } from './Field';
import CTMModule, { CTMRecord, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

export type Module = CTMStrictRecord &
  CTMRecord & {
    id: string;
    name: string;
    fields: Field[];
  };

interface CustomModuleItemPaths {
  getTemplates: ({ id }: { id: string | number }) => string;
}

interface CustomModuleCollectionPaths {
  getSections: ({ id }: { id: string | number }) => string;
}

const module: CTMModule<Module, CustomModuleItemPaths, CustomModuleCollectionPaths> = {
  id: '96e7c6b8-b5b9-4053-99c3-e9f6436a84ca',
  dataClass: 'CTM\\CustomField\\Entity\\Module',
  urlPrefix: 'core-MODULE',
  name: 'Moduły',
  role: null,
  api: {
    item: {
      get: ({ id }) => `/custom-fields/modules/${id}`,
      getTemplates: ({ id }) => `/custom-fields/modules/${id}/templates`,
      put: ({ id }) => `/custom-fields/modules/${id}`,
      delete: ({ id }) => `/custom-fields/modules/${id}`,
    },
    collection: {
      get: `/custom-fields/modules`,
      post: `/custom-fields/modules`,
      getSections: ({ id }) => `/custom-fields/module-sections?tab.template.module.id=${id}`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<Module, any>(record, ['name'], allFields);
    return record.name ?? name ? `${name ?? ''}` : null;
  },
  list: {
    columns: [
      {
        id: 'name',
        filterable: true,
        sortable: true,
        Header: 'Nazwa',
        accessor: 'name',
      },
    ],
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
