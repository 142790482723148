import { put } from '../../../helpers/Axios';
import { useAppDispatch } from '../../../store';
import { addSingleToast } from '../../../store/Toast/actions';
import PriorityPicker from '@Core/Components/PriorityPicker';
import { Priority, PriorityColor } from '@Core/Types/Priority';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, KeyboardDoubleArrowUpRounded, RemoveRounded } from '@mui/icons-material';
import { FC } from 'react';

type PriorityViewProps = {
  priority: Priority;
  resourceIRI?: string;
  showLabel?: boolean;
};

export const PriorityView: FC<PriorityViewProps> = ({ priority, resourceIRI, showLabel }) => {
  const dispatch = useAppDispatch();
  const updatePriority = (priority: null | Priority) => {
    if (priority === null) {
      return;
    }
    put(resourceIRI, { priority: priority })
      .then(() => {
        dispatch(
          addSingleToast({
            title: 'Priorytet został zaktualizowany',
            config: { appearance: 'success' },
          }),
        );
      })
      .catch(() => {
        dispatch(
          addSingleToast({
            title: 'Nie udało się zaktualizować priorytetu',
            config: { appearance: 'error' },
          }),
        );
      });
  };

  if (resourceIRI) {
    return <PriorityPicker value={priority} onChange={updatePriority} disableNull={true} />;
  }

  switch (priority) {
    case Priority.LOW:
      return (
        <span>
          <KeyboardArrowDownRounded style={{ color: PriorityColor.LOW }} /> {showLabel && 'Niski'}
        </span>
      );
    case Priority.MEDIUM:
      return (
        <span>
          {' '}
          <RemoveRounded style={{ color: PriorityColor.MEDIUM }} /> {showLabel && 'Średni'}
        </span>
      );
    case Priority.HIGH:
      return (
        <span>
          {' '}
          <KeyboardArrowUpRounded style={{ color: PriorityColor.HIGH }} /> {showLabel && 'Wysoki'}
        </span>
      );
    case Priority.CRITICAL:
      return (
        <span>
          <KeyboardDoubleArrowUpRounded style={{ color: PriorityColor.CRITICAL }} /> {showLabel && 'Krytyczny'}
        </span>
      );
  }
};
