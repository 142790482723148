import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { DeliveryMethodNameAlias } from '@Delivery/DeliveryMethod';
import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const NameAliases: CustomFieldComponentType = ({ field, value, onChange, readonly }) => {
  const [names, setNames] = useState<DeliveryMethodNameAlias[]>(Object.values(value ?? []));

  useEffect(() => {
    if (JSON.stringify(names) !== JSON.stringify(value)) {
      onChange(names, field.id);
    }
  }, [JSON.stringify(names)]);

  const addNewNameAlias = () => {
    Swal.fire<string>({
      title: 'Podaj nowy alias',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Dodaj',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => false,

      preConfirm: async val => {
        if (!val || val.length === 0) {
          return Swal.showValidationMessage('Podaj nazwę');
        }
        return val;
      },
    }).then(result => {
      if ((result.value ?? '').length > 0) {
        setNames([...names, { name: result.value ?? '' }]);
      }
    });
  };

  return (
    <Table style={{ minWidth: 640 }}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} style={{ textAlign: 'center' }}>
            Aliasy nazwy
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ width: 40 }}></TableCell>
          <TableCell>Nazwa</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {names.map((name, index) => (
          <TableRow key={index}>
            <TableCell>
              <Button variant="contained" color="error" onClick={() => setNames(names.filter((_, i) => i !== index))} className="w-100">
                Usuń
              </Button>
            </TableCell>
            <TableCell>{name.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={2}>
            <Button variant="contained" color="success" onClick={addNewNameAlias} className="w-100">
              Dodaj alias
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default NameAliases;
