import InheredSection from '@Components/CustomFields/InheredSection';
import CustomSection from 'components/CustomFields/CustomSection';
import { Row } from 'reactstrap';

const CustomTab = ({ tab, values, onUpdate, readonly, fields, components, recordId, forceReadonlyField }) => {
  return (
    <>
      <Row>
        <div className="accordion">
          {tab.sections.map(section =>
            section['@type'] === 'ModuleSection' ? (
              <CustomSection
                section={section}
                key={section.id}
                values={values}
                fields={fields}
                components={components}
                useCollapse={tab.sections.length > 1}
                useGridLayout={section.useGridLayout}
                onUpdate={onUpdate}
                readonly={readonly}
                recordId={recordId}
                forceReadonlyField={forceReadonlyField}
              />
            ) : (
              <InheredSection
                key={section.id}
                section={section}
                values={values}
                fields={fields}
                components={components}
                useCollapse={tab.sections.length > 1}
                useGridLayout={section.useGridLayout}
                onUpdate={onUpdate}
                readonly={readonly}
                recordId={recordId}
                forceReadonlyField={forceReadonlyField}
              />
            ),
          )}
        </div>
      </Row>
    </>
  );
};

export default CustomTab;
