import { ActionPattern } from 'redux-saga/effects';

export const LOGIN_USER: ActionPattern = 'LOGIN_USER';
export const LOGIN_USER_BY_PIN: ActionPattern = 'LOGIN_USER_BY_PIN';
export const LOGIN_SUCCESS: ActionPattern = 'LOGIN_SUCCESS';

export const LOGOUT_USER: ActionPattern = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS: ActionPattern = 'LOGOUT_USER_SUCCESS';
export const API_ERROR: ActionPattern = 'LOGIN_API_ERROR';
export const SET_PERMISSIONS: ActionPattern = 'SET_PERMISSIONS';
export const CHANGE_DEFAULT_WAREHOUSE: ActionPattern = 'CHANGE_DEFAULT_WAREHOUSE';
