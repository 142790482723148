import { get } from '../../../helpers/Axios';
import { AxiosRequestConfig } from 'axios';
import { UseQueryResult, useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

function useRecord<T>(
  path: string,
  config?: AxiosRequestConfig,
  options?: Omit<UseQueryOptions<T, unknown, T>, 'queryKey'>,
): UseQueryResult<T> {
  return useQuery<T>(path, () => get(path, config), options);
}

export default useRecord;
