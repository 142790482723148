import { Tooltip } from '@mui/material';

interface ProductPlaceProps {
  place: string;
}

const ProductPlace = ({ place }: ProductPlaceProps) => {
  return (
    <Tooltip title={place}>
      <span>
        {place.substring(0, 20)}
        {place.length > 20 && <span>...</span>}
      </span>
    </Tooltip>
  );
};

export default ProductPlace;
