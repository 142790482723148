import * as DocumentApi from '../../helpers/Api/Warehouse/Document/Document';
import { createModuleColumnConfig, createPriorityColumn } from '../../helpers/ModuleUtils';
import { SelectApiColumnFilter } from '@Components/DataGrid/Filter';
import SelectStatusColumnFilter from '@Components/DataGrid/Filter/SelectStatusColumnFilter';
import ModuleList from '@Components/Module/ModuleList';
import { Col, Container } from '@Components/Theme/Grid';
import MoneyView from '@Components/View/MoneyView';
import Contractor from '@Contractor/Contractor';
import StatusView from '@Core/Components/StatusView';
import { CTMListColumn } from '@Core/Types/CTMModule';
import { ClientOrder } from '@Ecommerce/ClientOrder';
import ClientOrderSource from '@Ecommerce/ClientOrderSource';
import EcommerceSelectStatusColumnFilter from '@Ecommerce/Components/SelectColumStatusFilter';
import SourceView from '@Ecommerce/Components/SourceView';
import ProductionOrderQuantity from '@Manufacture/Components/ProductionOrder/ProductionOrderQuantity';
import Product, { Product as ProductModel } from '@Manufacture/Product';
import { ProductionOrder } from '@Manufacture/ProductionOrder';
import { AssemblingOrder } from '@Warehouse/AssemblingOrder';
import WarehouseSelectStatusColumnFilter from '@Warehouse/Components/AssemblingOrder/SelectColumStatusFilter';
import DocumentType from '@Warehouse/DocumentType';
import Place from '@Warehouse/Place';
import { PlaceEvent, placeEvents } from '@Warehouse/PlaceEvent';
import Warehouse from '@Warehouse/Warehouse';
import Breadcrumbs from 'components/Theme/Common/Breadcrumb';
import dayjs from 'dayjs';
import { FC } from 'react';

const assemblingColumns: CTMListColumn<AssemblingOrder>[] = [
  createPriorityColumn<AssemblingOrder>(
    record => record.clientOrder?.priority ?? null,
    record => record.clientOrder?.['@id'],
    'clientOrder.priority',
  ),
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    minWidth: 160,
    flex: 1,
  },
  {
    id: 'clientOrder.number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'clientOrder.number',
  },
  {
    id: 'clientOrder.contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('clientOrder.contractor', Contractor),
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'warehouse-assembling-order'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: WarehouseSelectStatusColumnFilter,
  },
];

const clientOrderColumns: CTMListColumn<ClientOrder>[] = [
  createPriorityColumn<ClientOrder>(
    (record: ClientOrder) => record.priority,
    (record: ClientOrder) => record['@id'],
    'priority',
  ),
  {
    id: 'number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'number',
  },
  {
    id: 'contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('contractor', Contractor),
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'ecommerce-client-order'} status={status} />,
    filterable: true,
    Filter: EcommerceSelectStatusColumnFilter,
    filter: 'equals',
  },
  {
    id: 'totalPriceGrossHomeCurrency',
    Header: 'Wartość zamówienia',
    accessor: ({ totalPriceGross, totalPriceGrossHomeCurrency, currency, exchangeRateDate, createdAt, realizedAt }) => (
      <MoneyView
        value={totalPriceGross}
        homeCurrencyValue={totalPriceGrossHomeCurrency}
        currency={currency}
        exchangeDate={(exchangeRateDate ? dayjs(exchangeRateDate) : dayjs(realizedAt ?? createdAt ?? new Date()).subtract(1, 'day')).format(
          'YYYY-MM-DD',
        )}
      />
    ),
    filterable: true,
    sortable: true,
    minWidth: 160,
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'orderSource.id',
    Header: 'Źródło',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('orderSource', ClientOrderSource),
    accessor: row => (typeof row.orderSource !== 'string' ? <SourceView source={row.orderSource} /> : null),
  },
];

const placeEventsColumns: CTMListColumn<PlaceEvent>[] = [
  {
    id: 'document.id',
    Header: 'Dokument',
    accessor: 'document.number',
    filterable: true,
    sortable: true,
    Filter: SelectApiColumnFilter,
    filterSearchUrl: '/warehouse/documents',
    filterSearchField: 'id',
    filterFetchByValue: DocumentApi.fetch,
  },
  {
    id: 'document.type.id',
    Header: 'Rodzaj dokumentu',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('document.type', DocumentType),
  },
  {
    id: 'type',
    Header: 'Rodzaj zdarzenia',
    accessor: ({ type }) => placeEvents[type],
    filterable: false,
    sortable: true,
  },
  {
    id: 'product.id',
    Header: 'Towar',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig<ProductModel>('product', Product),
  },
  {
    id: 'quantityBefore',
    Header: 'Ilość przez zdarzeniem',
    accessor: ({ quantityBefore, unit }) => `${quantityBefore} ${unit?.name ?? 'szt.'}`,
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'quantity',
    Header: 'Ilość',
    accessor: ({ quantity, unit }) => `${quantity} ${unit?.name ?? 'szt.'}`,
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'unitPriceGross',
    Header: 'Cena jed. brutto',
    accessor: ({ unitPriceGross, unit }) => `${(unitPriceGross / 100).toFixed(2)}zł/${unit?.name ?? 'szt.'}`,
    filterable: false,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'storedAt',
    Header: 'Data zdarzenia',
    accessor: ({ storedAt }) => (storedAt ? dayjs(storedAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    minWidth: 160,
    noBreak: true,
  },
  {
    id: 'supplier',
    Header: 'Dostawca',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('document.supplier', Contractor),
  },
  {
    id: 'recipient',
    Header: 'Odbiorca',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('document.recipient', Contractor),
  },
  {
    id: 'place.warehouse.id',
    Header: 'Magazyn',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('place.warehouse', Warehouse),
  },
  {
    id: 'place.id',
    Header: 'Miejsce magazynowe',
    filterable: true,
    sortable: true,
    width: 50,
    ...createModuleColumnConfig('place', Place),
  },
];

const productionOrdersColumns: CTMListColumn<ProductionOrder>[] = [
  {
    id: 'number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'number',
  },
  {
    id: 'rows.product.symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    noBreak: true,
    accessor: 'product.symbol',
    filterSearchField: 'rows.product.symbol',
  },
  {
    id: 'rows.product.name',
    filterable: true,
    sortable: true,
    Header: 'Produkt',
    noBreak: true,
    accessor: 'product.name',
    filterSearchField: 'rows.product.name',
  },
  {
    id: 'rows.quantity',
    filterable: true,
    sortable: true,
    Header: 'Ilość',
    Cell: ({ row }) => (
      <ProductionOrderQuantity
        key={row.original.rowId}
        id={row.original.rowId}
        quantity={row.original.quantity}
        url={'/manufacture/production-order-rows'}
      />
    ),
    noBreak: true,
    accessor: 'quantity',
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'manufacture-production-order'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: ({ column }) => <SelectStatusColumnFilter column={column} moduleName={'manufacture-production-order'} property={'status'} />,
  },
  {
    id: 'deadlineAt',
    Header: 'Data realizacji',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
];

type DashboardListBlockProps = {
  moduleName: string;
  defaultFilters?: any[];
  columns: any;
  label: string;
  defaultPerPage?: number;
  perPageOptions?: number[];
};

const DashboardListBlock: FC<DashboardListBlockProps> = ({
  moduleName,
  defaultFilters,
  columns,
  label,
  defaultPerPage,
  perPageOptions,
}) => {
  return (
    <div style={{ border: '2px solid #2a3042', borderRadius: '5px' }}>
      <h4 style={{ background: '#2a3042', color: '#dedede', padding: '4px 8px', textAlign: 'center', margin: 0 }}>{label}</h4>
      <div>
        <ModuleList
          moduleName={moduleName}
          defaultFilters={defaultFilters}
          overrideListProps={{
            queryKey: moduleName,
            fullWidth: true,
            disableTop: true,
            storeFilters: false,
            columns: columns,
            defaultPerPage,
            minRows: defaultPerPage,
            perPageOptions,
          }}
          overrideFormProps={{
            disableCreateNewRecord: true,
            disableEditRecord: true,
            disableRemoveRecord: true,
            disableCustomActions: true,
          }}
        />
      </div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <div className="container-fluid">
      <Breadcrumbs breadcrumbItem="Dashboard" />
      <Container>
        <Col lg={8} md={12}>
          <DashboardListBlock
            label={'Najnowsze zamówienia klientów'}
            moduleName={'ecommerce-client-order'}
            defaultFilters={[{ id: 'exists[parent]', value: false }]}
            columns={clientOrderColumns}
            defaultPerPage={15}
            perPageOptions={[5, 10, 15]}
          />
        </Col>
        <Col lg={4} md={12}>
          <DashboardListBlock
            label={'Zlecenia kompletacji oczekujące na realizację'}
            moduleName={'warehouse-assembling-order'}
            defaultFilters={[{ id: 'status', value: 1000 }]}
            columns={assemblingColumns}
            defaultPerPage={5}
            perPageOptions={[5, 10, 20]}
          />
          <hr />
          <DashboardListBlock
            label={'Nowe zlecenia produkcyjne'}
            moduleName={'manufacture-production-order'}
            defaultFilters={[{ id: 'status', value: 500 }]}
            columns={productionOrdersColumns}
            defaultPerPage={5}
            perPageOptions={[5, 10, 20]}
          />
        </Col>

        <Col lg={12} md={12}>
          <DashboardListBlock label={'Ostatnie zdarzenia magazynowe'} moduleName={'warehouse-place-events'} columns={placeEventsColumns} />
        </Col>
      </Container>
    </div>
  );
};

export default Dashboard;
