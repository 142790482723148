import { useModuleContext } from '../../../context/ModulesContext';
import { get } from '../../../helpers/Axios';
import { Field } from '../Field';
import { Template } from '../Template';
import Section from './Section';
import ListPicker from '@Components/Form/ListPicker';
import { Col, Container } from '@Components/Theme/Grid';
import CoreModule from '@CustomFields/CoreModule';
import { AddCircle, HighlightOff } from '@mui/icons-material';
import { Box, Button, Paper } from '@mui/material';
import { MemoStrongCompare } from 'Modules/Common/Utils/MemoComparision';
import { confirmRemove } from 'common/sweetalerts';
import { FC, ReactNode, memo, useState } from 'react';
import { useQuery } from 'react-query';
import { Modal, ModalBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

type NewInheredSectionProps = {
  onClose: (value: boolean) => void;
  onSave: (value: object) => void;
  fields: Field[];
};

const NewInheredSection: FC<NewInheredSectionProps> = ({ onClose, fields, onSave }) => {
  const [selectedField, setSelectedField] = useState<null | Field>(null);
  const [selectedSection, setSelectedSection] = useState<null | object>(null);
  const relationFields = fields.filter(field => field.type === 'RELATION');
  const selectedFieldModule = useModuleContext(selectedField?.config?.targetModule, 'dataClass', false);
  const { data: availableSections } = useQuery(
    ['availableSections', selectedFieldModule?.configuration?.id ?? '_'],
    () => get(CoreModule.api.collection.getSections({ id: selectedFieldModule?.configuration?.id ?? '_' })),
    {},
  );

  return (
    <>
      <div className="modal-header-nav-bar">
        <div className={'modal-header-title'}>Dodaj sekcję z innego modułu</div>
        <div>
          <div className={'modal-header-close'}>
            <HighlightOff onClick={() => onClose(false)} />
          </div>
        </div>
      </div>
      <Paper sx={() => ({ padding: 4 })}>
        <ListPicker<Field>
          value={selectedField}
          label={'Wybierz pole'}
          options={relationFields}
          optionTrackBy="@id"
          optionLabel={fieldCfg => `(${fieldCfg.labelerId}) ${(fieldCfg.name ?? '') === '' ? 'Nazwa pola' : fieldCfg.name}`}
          onChange={(selectedFieldIri: null | string, fieldName: null | string, selectedFieldObject) =>
            setSelectedField(selectedFieldObject)
          }
          name={'field'}
        />
        {availableSections && (
          <ListPicker<any>
            value={selectedSection}
            label={'Wybierz sekcję'}
            options={availableSections?.['hydra:member'] ?? []}
            optionTrackBy="@id"
            optionLabel={section =>
              `${section?.tab?.template?.name ?? 'Systemowy'} -> ${section?.tab?.name ?? 'Domyślna'} -> ${section.name ?? 'Domyślna'}`
            }
            onChange={(selectedSectionIri: null | string, sectionName: null | string, pickedSection) => {
              setSelectedSection(pickedSection);
            }}
            name={'section'}
          />
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="info" onClick={() => onClose(false)}>
            Anuluj
          </Button>
          {selectedField && selectedSection && (
            <Button
              variant="contained"
              color="success"
              onClick={() =>
                onSave({
                  inheredFromField: selectedField,
                  inheredSection: selectedSection,
                })
              }
            >
              Zapisz
            </Button>
          )}
        </div>
      </Paper>
    </>
  );
};

interface TabPanelProps {
  children?: ReactNode;
  sections: any[];
  onFieldSaved: any;
  moduleId: any;
  id: string;
  selectedTab: null | string;
  fields: Field[];

  updateTemplate: (Template) => Template;
}

function TabPanel(props: TabPanelProps) {
  const { sections, id, selectedTab, fields, updateTemplate, onFieldSaved, moduleId } = props;
  const [open, setOpen] = useState(false);

  const addNewSection = () => {
    updateTemplate(prev => ({
      ...prev,
      tabs: prev.tabs.map(prevTab => {
        if (prevTab.id === selectedTab) {
          prevTab.sections = [
            ...prevTab.sections,
            {
              id: uuidv4(),
              position: prevTab.sections.length + 1,
              useGridLayout: true,
              name: '',
              layout: [],
            },
          ];
        }

        return prevTab;
      }),
    }));
  };
  const onNewInheredSection = section => {
    updateTemplate(prev => ({
      ...prev,
      tabs: prev.tabs.map(prevTab => {
        if (prevTab.id === selectedTab) {
          prevTab.sections = [
            ...prevTab.sections,
            {
              ...section,
              id: uuidv4(),
              position: prevTab.sections.length + 1,
              name: `${section.inheredFromField.name} -> ${section.inheredSection.name}`,
            },
          ];
        }

        return prevTab;
      }),
    }));

    setOpen(false);
  };
  const onRemove = id => {
    confirmRemove(
      () => {
        return new Promise<boolean>(res => {
          updateTemplate(prev => ({
            ...prev,
            tabs: prev.tabs.map(prevTab => {
              if (prevTab.id === selectedTab) {
                prevTab.sections = prevTab.sections
                  .filter(el => el.id !== id)
                  .map((newSection, index) => ({ ...newSection, position: index + 1 }));
              }

              return prevTab;
            }),
          }));
          res(true);
        });
      },
      'Czy jesteś pewien skasowania tej sekcji?',
      'Jeśli po skasowaniu się rozmyślisz możesz użyć przycisku zamknij spowoduje to utratę wszystkich niezapisanych zmian w szablonie.',
      false,
    );
  };
  return (
    <div role="tabpanel" hidden={id !== selectedTab} id={`simple-tabpanel-${selectedTab}`} aria-labelledby={`simple-tab-${selectedTab}`}>
      {id === selectedTab && (
        <>
          <Box sx={{ p: 0 }} border={1} borderColor={'#eef1fd'}>
            {sections.map(el => (
              <Section
                key={el.id}
                tabId={id}
                fields={fields}
                section={el}
                updateTemplate={updateTemplate}
                F
                onRemove={onRemove}
                onFieldSaved={onFieldSaved}
              />
            ))}
            <Container>
              <Col sm={12} md={6}>
                <Paper
                  variant="outlined"
                  style={{
                    padding: '64px 0',
                    textAlign: 'center',
                    fontSize: '1.7rem',
                    color: '#2a3042',
                    cursor: 'pointer',
                  }}
                  onClick={() => addNewSection()}
                >
                  <AddCircle /> Dodaj nową sekcję
                </Paper>
              </Col>
              <Col sm={12} md={6}>
                <Paper
                  variant="outlined"
                  style={{
                    padding: '64px 0',
                    textAlign: 'center',
                    fontSize: '1.7rem',
                    color: '#2a3042',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <AddCircle /> Dodaj sekcję z powiązanego modułu
                </Paper>
              </Col>
            </Container>
          </Box>
          <Modal
            isOpen={open}
            centered={true}
            size={'sm'}
            backdrop={true}
            toggle={() => setOpen(!open)}
            style={{ minWidth: '320px', maxHeight: '90vw' }}
            className={'list-selector-modal'}
          >
            <ModalBody className="py-3 px-5">
              {open && <NewInheredSection onClose={() => setOpen(false)} onSave={onNewInheredSection} fields={fields} />}
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
}

export default memo(TabPanel, MemoStrongCompare);
