import SidebarConfig from './SidebarConfig';
import Loader from '@Components/Theme/Common/Loader';
import useModuleData from '@Core/Hooks/useModuleData';
import { useIsGrantedFn } from 'common/hooks/isGranted';
import { tToggle } from 'helpers/Common';
// MetisMenu
import MetisMenu from 'metismenujs';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
//i18n
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';

const TemplatedCreateNewLink = ({ el }) => {
  const { t } = useTranslation();
  const moduleData = useModuleData(el.moduleId);

  if (moduleData.isLoading) {
    return <Loader />;
  }
  const notSystemTemplates = moduleData.data.templates.filter(el => !el.system);

  return (
    <>
      <li>
        <Link to={el.to} data-matchregexp={el.matchRegexp}>
          <i className={el.iconClass}></i>
          <span>{t(el.title)}</span>
        </Link>
      </li>
      {notSystemTemplates.map((template, index) => (
        <li key={index}>
          <Link to={`${el.to}?templateId=${template.id}`} data-matchregexp={el.matchRegexp}>
            <i className={el.iconClass}></i>
            {template.image && (
              <img
                style={{ maxWidth: '30px', maxHeight: '30px', height: '100%', borderRadius: 10, marginRight: 5 }}
                src={template.image}
                alt={template.name}
              />
            )}
            <span>{template.name}</span>
          </Link>
        </li>
      ))}
    </>
  );
};

const SidebarContent = props => {
  const ref = useRef();

  // Monit if sidebar is enabled
  const [isSidebarEnabled, setIsSidebarEnabled] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setIsSidebarEnabled(document.body.classList.contains('sidebar-enable'));
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const initMenu = () => {
      new MetisMenu('#side-menu');
    };
    initMenu();
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        const regexp = items[i].dataset?.matchregexp;

        if (pathName === items[i].pathname || (regexp && new RegExp(regexp).test(pathName))) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    let el;
    while ((el = document.querySelector('.mm-active'))) {
      el.classList.remove('mm-active');
    }
    while ((el = document.querySelector('.active'))) {
      el.classList.remove('active');
    }
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  function handleSidebarMenuClick(hasChildren) {
    if (isSidebarEnabled && hasChildren) {
      tToggle();
    }
  }

  const renderSidebarSection = (el, index) => {
    const hasChildren = el.children && el.children.length;
    switch (el.type) {
      case 'DIVIDER':
        return (
          <li key={`div_${index}_${el.to}`} className="menu-title">
            {props.t(el.title)}{' '}
          </li>
        );
      case 'CREATE_NEW_LINK':
        return <TemplatedCreateNewLink key={`div_${index}_${el.to}`} el={el} />;
      case 'LINK':
        return (
          <li key={`link_${index}_${el.to}`} id={el.title}>
            <Link
              to={hasChildren ? '/#' : el.to}
              className={hasChildren ? 'has-arrow' : ''}
              data-matchregexp={el.matchRegexp}
              onClick={() => handleSidebarMenuClick(hasChildren)}
            >
              <i className={el.iconClass}></i>
              <span>{props.t(el.title)}</span>
            </Link>
            {hasChildren > 0 && renderChildren(el)}
          </li>
        );
    }
  };

  const renderChildren = el => {
    return <ul className="sub-menu">{el.children.map((eli, index) => renderSidebarSection(eli, index))}</ul>;
  };

  const isGranted = useIsGrantedFn();

  const filterCollection = el => {
    if (el.children && el.children.length > 0) {
      el.children = el.children.filter(elc => {
        if (elc.children && elc.children.length > 0) {
          elc.children = elc.children.filter(filterCollection);

          if (elc.children.length === 0) {
            return false;
          }
        }

        return !elc.role || isGranted(elc.role);
      });

      if (el.children.length === 0) {
        return false;
      }
    }

    return !el.role || isGranted(el.role);
  };

  const sidebarElements = SidebarConfig.filter(filterCollection);

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sidebarElements.map((el, index) => renderSidebarSection(el, index))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
