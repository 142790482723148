import { post } from '../../../../helpers/Axios';
import { ProcurementOrderModule } from '@Modules/CTMModules';
import { Add } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

const ScheduleButton: FC = () => {
  const dispatch = useDispatch();

  const generate = (): void => {
    post(ProcurementOrderModule.configuration.api.custom.schedule, {}).then((): void => {
      dispatch(addSingleToast({ title: `Wygenerowano zlecenia`, config: { appearance: 'success' } }));
      location.reload();
    });
  };
  return (
    <Button
      variant={'contained'}
      color={'success'}
      className="d-flex items-center"
      style={{ margin: '0', fontSize: '13px', height: '32px' }}
      onClick={generate}
    >
      <Add /> Generuj zamówienia
    </Button>
  );
};

export default ScheduleButton;
