import { useModuleContext } from '../../context/ModulesContext';
import ModuleListPicker from '../Module/ModuleListPicker';
import { Tooltip } from '@mui/material';
import DownloadSectionContent from 'Modules/CustomFields/CoreComponents/DownloadSectionContent';
import classnames from 'classnames';
import CustomField from 'components/CustomFields/CustomField';
import { get } from 'helpers/Axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { useAppSelector } from 'store';
import { addSingleToast } from 'store/Toast/actions';

const BaseGridLayout = ({ children }) => {
  return (
    <Col>
      <Row>{children}</Row>
    </Col>
  );
};

const ResponsiveGridLayout = WidthProvider(Responsive);
const CustomSection = ({
  section,
  values,
  onUpdate,
  readonly,
  useCollapse,
  fields,
  useGridLayout = true,
  components,
  recordId,
  forceReadonlyField,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);
  const [layouts, setLayouts] = useState({
    lg: section.layout,
    md: section.layout,
    sm: section.layout,
    xs: section.layout,
    xxs: section.layout,
  });

  const selectedLanguage = useAppSelector(state => state.Root.language);
  const location = useLocation();
  const moduleName = useMemo(() => location.pathname.split('/')[2] ?? null, [location.pathname]);
  const module = useModuleContext(moduleName);
  const CollapseWrapper = useCollapse ? Collapse : React.Fragment;
  const CollapseProps = useCollapse
    ? {
        isOpen: expanded,
        className: 'accordion-collapse custom-field-section-collapse',
      }
    : {};
  const ResponsiveGridLayoutWrapper = useGridLayout ? ResponsiveGridLayout : BaseGridLayout;
  const ResponsiveGridLayoutProps = useGridLayout
    ? {
        className: 'layout',
        draggableHandle: '.___',
        isDraggable: false,
        isResizable: false,
        useCSSTransforms: true,
        autoSize: true,
        onLayoutChange: (curr, newLayout) => {
          setLayouts(newLayout);
        },
        // compactType: 'vertical',
        rowHeight: 43,
        margin: [0, 0],
        layouts: layouts,
        breakpoints: {
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        },
        cols: { lg: 12, md: 1, sm: 1, xs: 1, xxs: 1 },
      }
    : {};

  const fieldsWithTabindex = useMemo(() => {
    let tabindex = section.position * 1000 + 1;
    const mappedFields = [...fields];
    [...section.layout]
      .sort((a, b) => a.y - b.y || a.x - b.x)
      .forEach(layoutItem => {
        if (mappedFields.find(el => el.id === layoutItem.i)) {
          mappedFields.find(el => el.id === layoutItem.i)._tabindex = tabindex++;
        }
      });

    const sectionFields = section.layout.map(el => el.i);

    return mappedFields.filter(el => sectionFields.includes(el.id));
  }, [section?.position, fields]);

  const allowedToDisplay = fieldOrSection => {
    let hideField = false;

    if (fieldOrSection.hideRules && fieldOrSection.hideRules.length > 0) {
      const resolvedConditions = fieldOrSection.hideRules.map(hideRule => {
        const left = values?.[hideRule.field]?.['@id'] ?? values?.[hideRule.field];
        const right = hideRule.value;

        switch (hideRule.cmp) {
          case 'eq':
            return left === right || (!left && !right);
          case 'neq':
            return left !== right;
          case 'like':
            return (left + '').toLowerCase().includes((right + '').toLowerCase());
          case 'nlike':
            return !(left + '').toLowerCase().includes((right + '').toLowerCase());
          default:
            return false;
        }
      });

      hideField = resolvedConditions.filter(el => el).length === fieldOrSection.hideRules.length;
    }

    return !hideField;
  };

  const fieldsAllowedToDisplay = useMemo(() => {
    return fieldsWithTabindex.filter(allowedToDisplay);
  }, [values, fieldsWithTabindex]);

  useEffect(() => {
    setLayouts({
      lg: section.layout,
      md: section.layout,
      sm: section.layout,
      xs: section.layout,
      xxs: section.layout,
    });
  }, [fieldsAllowedToDisplay]);

  const onSelect = object => {
    get(object['@id']).then(response => {
      fieldsAllowedToDisplay.map(field => {
        if (response?.['@formValues']?.[field.id]) {
          onUpdate(response?.['@formValues']?.[field.id], field.id);
        }
      });

      dispatch(
        addSingleToast({
          title: `Skopiowano wartość z wybranego obiektu.`,
          config: { appearance: 'success' },
        }),
      );
    });
  };

  if (!allowedToDisplay(section)) {
    return null;
  }

  return (
    <>
      <div className={classnames({ 'accordion-item cf-tab': useCollapse })} key={section.id}>
        {useCollapse && (
          <h2 className="accordion-header cf-tab" id={section.id}>
            <div
              className={classnames('accordion-button', { collapsed: !expanded })}
              onClick={() => setExpanded(!expanded)}
              style={{ cursor: 'pointer' }}
            >
              {section.name || 'Nazwa sekcji'}
              <DownloadSectionContent sectionId={section.id} recordId={recordId} />
              {module && !readonly && (
                <ModuleListPicker
                  name={'_'}
                  disabled={false}
                  moduleName={moduleName}
                  onChange={onSelect}
                  disableClear={true}
                  defaultFilters={null}
                  onMultipleSelect={undefined}
                  overrideUrl={undefined}
                  moduleFormComponent={undefined}
                  moduleFormComponentProps={undefined}
                  moduleFormWrapperProps={undefined}
                >
                  <Button className="btn ml-1 btn-sm" style={{ height: '100%' }}>
                    <Tooltip title={'Skopiuj pole z innego rekrodu'}>
                      <span>
                        <i className="mdi mdi-eye" style={{ padding: '0 4px' }} /> Pobierz zawartość
                      </span>
                    </Tooltip>
                  </Button>
                </ModuleListPicker>
              )}
            </div>
          </h2>
        )}

        <CollapseWrapper {...CollapseProps}>
          <div className={classnames('custom-section', { 'accordion-body': useCollapse })}>
            <ResponsiveGridLayoutWrapper {...ResponsiveGridLayoutProps}>
              {fieldsAllowedToDisplay.map((field, index) =>
                useGridLayout ? (
                  <div key={field.id}>
                    <div style={{ display: 'block', width: '100%', height: '100%' }}>
                      <CustomField
                        field={field}
                        fields={fields}
                        components={components}
                        value={(field.multiLanguage ? values?.[field.id]?.value?.[selectedLanguage] : values?.[field.id]) ?? null}
                        values={values}
                        onUpdate={onUpdate}
                        readonly={forceReadonlyField?.(field, values, fields, recordId) || readonly}
                        recordId={recordId}
                      />
                    </div>
                  </div>
                ) : (
                  <CustomField
                    key={field.id}
                    field={field}
                    fields={fields}
                    size={field.size.md}
                    components={components}
                    value={(field.multiLanguage ? values?.[field.id]?.value?.[selectedLanguage] : values?.[field.id]) ?? null}
                    values={values}
                    onUpdate={onUpdate}
                    readonly={forceReadonlyField?.(field, values, fields, recordId) || readonly}
                    recordId={recordId}
                    usedGridLayout={section.useGridLayout === true}
                  />
                ),
              )}
            </ResponsiveGridLayoutWrapper>
          </div>
        </CollapseWrapper>
      </div>
    </>
  );
};

export default CustomSection;
