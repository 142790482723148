import Contractor, { Contractor as ContractorModel } from '../Contractor/Contractor';
import CurrencyExchange from '../Core/Services/CurrencyExchange';
import Employee from '../Employee/Employee';
import { Employee as EmployeeModel } from '../Employee/Types/Employee';
import Client from './Client';
import CostOfPackingNet from './Components/CostOfPackingNet';
import CostOfPalletNet from './Components/CostOfPalletNet';
import CostOfShipmentNet from './Components/CostOfShipmentNet';
import Currency from './Components/Currency';
import CurrencyRateValue from './Components/CurrencyRateValue';
import GenerateAssemblingOrderButton from './Components/GenerateAssemblingOrderButton';
import TransactionTab from './Components/List/TransactionTab';
import OrderPositions, { computeClientOrderPriceGross, computeClientOrderPriceNet } from './Components/OrderPositions';
import ReservationPicker from './Components/ReservationPicker';
import SelectStatusColumnFilter from './Components/SelectColumStatusFilter';
import ShipmentField from './Components/ShipmentField';
import SourceView from './Components/SourceView';
import SplitOrder from './Components/SplitOrder';
import StatusPicker from './Components/StatusPicker';
import TotalPriceGross from './Components/TotalPriceGross';
import TotalPriceNetto from './Components/TotalPriceNetto';
import TotalPriceVat from './Components/TotalPriceVat';
import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import PriorityField from '@Core/Components/Fields/PriorityField';
import { Media } from '@Core/Types/ApiModel';
import { Priority } from '@Core/Types/Priority';
import DeliveryMethod, { DeliveryMethod as DeliveryMethodModel } from '@Delivery/DeliveryMethod';
import ClientOrderSource, { ClientOrderSource as ClientOrderSourceModel } from '@Ecommerce/ClientOrderSource';
import OrderPackages from '@Ecommerce/Components/ClientOrder/Packages/OrderPackages';
import OpenOrderLinks from '@Ecommerce/Components/OpenOrderLinks';
import { Product } from '@Manufacture/Types/Product';
import { Unit } from '@Manufacture/Unit';
import { Warning } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import StatusView from 'Modules/Core/Components/StatusView';
import { Status } from 'Modules/Core/Hooks/usePropertyValues';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import ChildTab from 'Modules/Ecommerce/Components/List/ChildTab';
import ViewTrouble from 'Modules/Ecommerce/Components/Trouble/ViewTrouble';
import DocumentTab from 'Modules/Warehouse/Components/Document/DocumentTab';
import classnames from 'classnames';
import PrintItemButton from 'components/DataGrid/Button/PrintItemButton';
import ReportDownloadButton from 'components/DataGrid/Button/ReportDownloadButton';
import MoneyView from 'components/View/MoneyView';
import dayjs, { Dayjs } from 'dayjs';
import { get, put } from 'helpers/Axios';
import { createModuleColumnConfig, createPriorityColumn, extractIRIFromRecord, setValuesToFormRecord } from 'helpers/ModuleUtils';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CardBody, CardTitle } from 'reactstrap';

export type ClientOrderLink = {
  name: string;
  url: string;
};

export type ClientOrderPackage = CTMRecord & {
  courierCode: string;
  courierPackageNumber: string;
  label: string | Media;
  packageLength: number;
  packageThickness: number;
  packageWidth: number;
  packageType: string;
  trackingStatus: string;
  trackingUrl: string;
  weigthKg: number;
};

export type ClientOrder = CTMRecord & {
  client?: string | Partial<ContractorModel>;
  trader?: string | Partial<EmployeeModel>;
  id?: string;
  realizedAtOffered?: null | Dayjs | string;
  parent: null | string | ClientOrder;
  children: any[];
  priority: Priority;
  links: ClientOrderLink[];
  status: number;
  orderSource: null | string | ClientOrderSourceModel;
  deliveryMethod: null | string | Partial<DeliveryMethodModel>;
  reservation: number;
  rows: {
    margin: number;
    product: string | Product;
    quantity: number;
    unit: null | string | Unit;
    vat: null | string | ValueAddedTax;
    unitPriceNet: number;
    unitPriceGross: number;
  }[];
  packages: ClientOrderPackage[];
};

const BoardHeader: FC<{ item: Status }> = ({ item }) => {
  const [values, setValues] = useState<{ totalPriceNetHomeCurrency: number; profitNetHomeCurrency: number }>({
    totalPriceNetHomeCurrency: 0,
    profitNetHomeCurrency: 0,
  });

  useEffect(() => {
    get(`ecommerce/client-order/status-values/${item.value}`).then(result => {
      setValues({
        totalPriceNetHomeCurrency: result.totalPriceNetHomeCurrency.toFixed(2),
        profitNetHomeCurrency: result.profitNetHomeCurrency.toFixed(2),
      });
    });
  }, [item]);

  return (
    <CardTitle className="mb-4" style={{ backgroundColor: item.color }}>
      {item.label}
      <div className="text-end">
        <p className="mb-0" style={{ fontSize: '11px' }}>
          {values.totalPriceNetHomeCurrency} wartości netto
        </p>
        <p className="mb-0" style={{ fontSize: '11px' }}>
          {values.profitNetHomeCurrency} zysku netto
        </p>
      </div>
    </CardTitle>
  );
};

const columns: CTMListColumn<ClientOrder>[] = [
  createPriorityColumn<ClientOrder>(
    (record: ClientOrder) => record.priority,
    (record: ClientOrder) => record['@id'],
    'priority',
  ),
  {
    id: 'number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'number',
  },
  {
    id: 'contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('contractor', Contractor),
  },
  {
    id: 'basket.client.id',
    Header: 'Klient/Os. Kontaktowa',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('basket.client', Client),
  },
  {
    id: 'trader.id',
    Header: 'Handlowiec',
    accessor: 'trader.relationFieldLabel',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('trader', Employee),
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'ecommerce-client-order'} status={status} />,
    filterable: true,
    Filter: ({ column }) => (
      <>
        <SelectStatusColumnFilter column={column} />
      </>
    ),
    filter: 'equals',
  },
  {
    id: 'totalPriceGrossHomeCurrency',
    Header: 'Wartość zamówienia',
    accessor: ({ totalPriceGross, totalPriceGrossHomeCurrency, currency, exchangeRateDate, createdAt, realizedAt }) => (
      <MoneyView
        value={totalPriceGross}
        homeCurrencyValue={totalPriceGrossHomeCurrency}
        currency={currency}
        exchangeDate={(exchangeRateDate ? dayjs(exchangeRateDate) : dayjs(realizedAt ?? createdAt ?? new Date()).subtract(1, 'day')).format(
          'YYYY-MM-DD',
        )}
      />
    ),
    filterable: true,
    sortable: true,
    minWidth: 160,
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'realizedAt',
    Header: 'Data realizacji',
    accessor: ({ realizedAt }) => (realizedAt ? dayjs(realizedAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'realizedAtOffered',
    Header: 'Data realizacji oferowana',
    accessor: ({ realizedAtOffered }) => (realizedAtOffered ? dayjs(realizedAtOffered).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'orderSource.id',
    Header: 'Źródło',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('orderSource', ClientOrderSource),
    accessor: row => (typeof row.orderSource !== 'string' ? <SourceView source={row.orderSource} /> : null),
  },
  {
    id: 'deliveryMethod.id',
    Header: 'Metoda dostawy',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('deliveryMethod', DeliveryMethod),
    accessor: row => (typeof row.deliveryMethod != 'string' ? row.deliveryMethod?.relationFieldLabel : null),
  },
];

type CustomActions = {
  generatePackages: (orderId: string) => Promise<Pick<ClientOrder, 'packages'>>;
};

const customApiActions: CustomActions = {
  generatePackages: orderId =>
    put<Pick<ClientOrder, 'packages'>>(`/ecommerce/client-orders/${orderId}/action/generate-packages`, { orderId }),
};

interface CustomClientOrderItemPaths {
  get: ({ id }: { id: string | number }) => string;
  trouble: ({ id }: { id: string | number }) => string;
  generatePackages: ({ id }: { id: string | number }) => string;
}

interface CustomClientOrderCollectionPaths {
  split: string;
}

const module: CTMModule<ClientOrder, CustomClientOrderItemPaths, CustomClientOrderCollectionPaths, CustomActions> = {
  id: 'f382ef34-b509-41b1-a228-264be88e707a',
  dataClass: 'CTM\\Ecommerce\\Entity\\ClientOrder',
  urlPrefix: 'ecommerce-client-order',
  name: 'Zam. od klientów',
  role: 'ECOMMERCE_ORDER',
  api: {
    item: {
      get: ({ id }) => `/ecommerce/client-orders/${id}`,
      put: ({ id }) => `/ecommerce/client-orders/${id}`,
      delete: ({ id }) => `/ecommerce/client-orders/${id}`,
      trouble: ({ id }) => `/ecommerce/client-orders/${id}/trouble`,
      generatePackages: ({ id }) => `/ecommerce/client-orders/${id}/action/generate-packages`,
    },
    collection: {
      get: `/ecommerce/client-orders`,
      post: `/ecommerce/client-orders`,
      split: `/ecommerce/client-orders/split`,
    },
    custom: customApiActions,
  },
  recordLabel: (record, allFields) => record.number,
  form: {
    prepareRecordToSave: record => {
      const totalNet = computeClientOrderPriceNet(record.rows ?? []);
      const totalGross = computeClientOrderPriceGross(record.rows ?? []);
      return {
        ...record,
        contractor: extractIRIFromRecord(record?.contractor),
        trader: extractIRIFromRecord(record?.trader),
        parent: extractIRIFromRecord(record?.parent),
        assemblingOrder: extractIRIFromRecord(record?.assemblingOrder),
        orderSource: extractIRIFromRecord(record?.orderSource),
        deliveryMethod: extractIRIFromRecord(record?.deliveryMethod),
        totalPriceNet: Math.round(totalNet),
        costOfShipmentNet: Math.round(record.costOfShipmentNet * 100),
        costOfPackingNet: Math.round(record.costOfPackingNet * 100),
        costOfPalletNet: Math.round(record.costOfPalletNet * 100),
        totalPriceVat: Math.round(totalGross - totalNet),
        totalPriceGross: Math.round(totalGross),
        rows: (record.rows ?? []).map(el => ({
          ...el,
          margin: el.margin ?? 0,
          product: extractIRIFromRecord(el.product),
          unit: extractIRIFromRecord(el.unit),
          vat: extractIRIFromRecord(el.vat),
          unitPriceNet: Math.round(el.unitPriceNet),
          unitPriceGross: Math.round(el.unitPriceGross),
        })),
        packages: (record.packages ?? []).map(el => ({
          ...el,
          label: extractIRIFromRecord(el.label),
        })),
      };
    },
    prepareFetchedRecord: (record, allFields) => {
      return setValuesToFormRecord(record, allFields, {
        totalPriceNet: (record.totalPriceNet ?? 0) / 100,
        totalPriceVat: (record.totalPriceVat ?? 0) / 100,
        totalPriceGross: (record.totalPriceGross ?? 0) / 100,
        costOfShipmentNet: (record.costOfShipmentNet ?? 0) / 100,
        costOfPackingNet: (record.costOfPackingNet ?? 0) / 100,
        costOfPalletNet: (record.costOfPalletNet ?? 0) / 100,
        profitNet: (record.profitNet ?? 0) / 100,
        profitNetHomeCurrency: (record.profitNetHomeCurrency ?? 0) / 100,
      });
    },
    onRecordChange: (recordNewState, recordOldState, originalRecord, module, setRecord) => {
      const exchangeRateDateId = module.fields.find(field => field.propertyPath === 'exchangeRateDate')?.id;
      const exchangeRateValueId = module.fields.find(field => field.propertyPath === 'exchangeRateValue')?.id;
      const statusId = module.fields.find(field => field.propertyPath === 'status')?.id;
      const realizedAtId = module.fields.find(field => field.propertyPath === 'realizedAt')?.id;
      const createdAtId = module.fields.find(field => field.propertyPath === 'createdAt')?.id;
      const currencyId = module.fields.find(field => field.propertyPath === 'currency')?.id;

      if (!exchangeRateDateId || !exchangeRateValueId || !statusId || !realizedAtId || !createdAtId || !currencyId) {
        return recordNewState;
      }

      if (recordNewState?.['@formValues']?.[statusId] < 3000 && recordNewState?.['@formValues']?.[realizedAtId]) {
        recordNewState['@formValues'][realizedAtId] = originalRecord?.['@formValues']?.[realizedAtId] ?? null;
      }

      if (recordNewState?.['@formValues']?.[statusId] >= 3000 && !recordNewState?.['@formValues']?.[realizedAtId]) {
        recordNewState['@formValues'][realizedAtId] = dayjs().format('YYYY-MM-DD');
      }

      if (!recordOldState?.['@formValues']?.[createdAtId]) {
        recordNewState['@formValues'][createdAtId] = dayjs().format('YYYY-MM-DD');
      }

      const oldCreated = recordOldState?.['@formValues']?.[createdAtId];
      const oldRealized = recordOldState?.['@formValues']?.[realizedAtId];
      const newCreated = recordNewState?.['@formValues']?.[createdAtId];
      const newRealized = recordNewState?.['@formValues']?.[realizedAtId];
      const newCurrency = recordNewState?.['@formValues']?.[currencyId];

      let exchangeRateDate = dayjs(newRealized ?? newCreated ?? oldRealized ?? oldCreated ?? dayjs())
        .subtract(1, 'day')
        .format('YYYY-MM-DD');

      if (exchangeRateDate) {
        while (!(dayjs(exchangeRateDate).get('day') >= 1 && dayjs(exchangeRateDate).get('day') <= 5)) {
          exchangeRateDate = dayjs(exchangeRateDate).subtract(1, 'day').format('YYYY-MM-DD');
        }
      }
      (async () => {
        let isCurrencyExchangeRateDateCorrect = false;
        let tmpCheckDate = exchangeRateDate;
        let checkLimit = 7;

        if (tmpCheckDate) {
          do {
            await CurrencyExchange.getCurrencyExchangeRate('PLN', newCurrency, tmpCheckDate)
              .then(res => {
                isCurrencyExchangeRateDateCorrect = true;
                if (res.getDate() !== exchangeRateDate || res.getMid() !== recordNewState['@formValues'][exchangeRateValueId]) {
                  setRecord(prevRecord => {
                    prevRecord.exchangeRateDate = res.getDate();
                    prevRecord['@formValues'][exchangeRateDateId] = res.getDate();
                    prevRecord['@formValues'][exchangeRateValueId] = res.getMid();

                    return prevRecord;
                  });
                }
              })
              .catch(() => {
                tmpCheckDate = dayjs(tmpCheckDate).subtract(1, 'day').format('YYYY-MM-DD');
              });
          } while (!isCurrencyExchangeRateDateCorrect && checkLimit-- > 0);
        }
      })();

      if (JSON.stringify(recordNewState) === JSON.stringify(recordOldState)) {
        return recordOldState;
      }
      return recordNewState;
    },
    defaultRecord: (allFields, currentUser) => {
      return {
        trader: currentUser,
      };
    },
    fieldComponents: {
      status: StatusPicker,
      reservation: ReservationPicker,
      rows: OrderPositions,
      currency: Currency,
      exchangeRateValue: CurrencyRateValue,
      totalPriceNet: TotalPriceNetto,
      totalPriceVat: TotalPriceVat,
      totalPriceGross: TotalPriceGross,
      costOfPackingNet: CostOfPackingNet,
      costOfShipmentNet: CostOfShipmentNet,
      costOfPalletNet: CostOfPalletNet,
      shipment: ShipmentField,
      priority: PriorityField,
      packages: OrderPackages,
    },
    forceReadonlyField: field => {
      return ['createdAt', 'realizedAt'].includes(field.propertyPath);
    },
    contextActions: ({ record }) => {
      return (
        <>
          {record.id && (
            <>
              <SplitOrder clientOrder={record} />
              <GenerateAssemblingOrderButton clientOrder={record} />
              <PrintItemButton
                id={record.number}
                name={'Zamówienie'}
                downloadUrl={`/ecommerce/client-orders/${record.id}`}
                parameters={{ type: 'order' }}
                description={'Pobierz plik PDF z zamówieniem.'}
                label={true}
                languageSelect={true}
              />
              <PrintItemButton
                id={record.number}
                name={'Proforma'}
                downloadUrl={`/ecommerce/client-orders/${record.id}`}
                description={'Pobierz plik PDF z proformą.'}
                parameters={{ type: 'invoice' }}
                label={true}
                languageSelect={true}
              />
              <ReportDownloadButton
                url={`/ecommerce/client-order/export?id=${record.id}`}
                name={`Zamówienie_${record.number.replace('/', '_').replace(' ', '_')}`}
                description={'Pobierz plik XLS z zamówieniem'}
                label={'Pobierz XLS'}
              />
              <OpenOrderLinks order={record} />
            </>
          )}
        </>
      );
    },
    customTabs: [
      {
        title: 'Podzlecenia',
        component: ChildTab,
      },
      {
        title: 'Dokumenty',
        component: DocumentTab,
        props: { field: 'owner', property: '@id' },
      },
      {
        title: 'Transakcje',
        component: TransactionTab,
      },
    ],
    inlineComponentAppendHeader: record => <ViewTrouble clientOrderId={record.id} initialTrouble={record.trouble} />,
  },
  list: {
    columns: columns,
    defaultFilters: [{ id: 'exists[parent]', value: false }],
    defaultOrderBy: [{ id: 'createdAt', desc: true }],
    inlineComponentPrependActions: (row, listRef) => (
      <>
        <PrintItemButton
          id={row.number}
          name={'Zamówienie'}
          downloadUrl={`/ecommerce/client-orders/${row.id}`}
          description={'Pobierz plik PDF z zamówieniem.'}
          label={false}
        />
      </>
    ),
    storeFilters: true,
    board: true,
    boardHeaderOverride: ({ item }) => <BoardHeader item={item} />,
    boardCardOverride: ({ item, module }) => {
      const textClasses = {
        'text-muted': !item.trouble,
        'text-white': item.trouble,
      };

      return (
        <CardBody
          className={classnames('borad-width', {
            'bg-danger': item.trouble,
          })}
        >
          <div>
            <h5 className="font-size-15">
              <Link
                to={module.showUrl(item.id)}
                className={classnames({
                  'text-dark': !item.trouble,
                  'text-white': item.trouble,
                })}
              >
                {item.number}
                {item.trouble && (
                  <Tooltip title={item.trouble}>
                    <Warning className={'ml-1'} />
                  </Tooltip>
                )}
              </Link>
            </h5>
            <p className={classnames('mb-4', textClasses)}>{item.contractor?.relationFieldLabel}</p>
            <p className={classnames('mb-4', textClasses)}>{item.trader?.relationFieldLabel}</p>
          </div>
          <div className="text-end">
            <p className={classnames('mb-0', textClasses)}>Utworzono {dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
            {item.realizedAtOffered && (
              <p className={classnames('mb-0', textClasses)}>Realizacja {dayjs(item.realizedAtOffered).locale('pl').format('LLL')}</p>
            )}
          </div>
        </CardBody>
      );
    },
  },
};

export default module;
