import { LoginState } from '../../../store/auth/login/reducer';
import GroupIcon from '@mui/icons-material/Group';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { createElement } from 'react';

export default function createRoomAvatarSrc(room: Room | undefined, currentUser: LoginState['user']['details'] | undefined) {
  if (!room) {
    return undefined;
  }

  if (room.members.length === 1) {
    return room.members[0].user.image?.contentUrl;
  }

  const membersWithoutCurrentUser = room.members.filter(member => member.user['@id'] !== currentUser?.['@id']);

  if (membersWithoutCurrentUser.length === 1) {
    return membersWithoutCurrentUser[0].user.image?.contentUrl;
  }

  return createElement(GroupIcon);
}
