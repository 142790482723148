import RelationSinglePicker from '../../Form/RelationSinglePicker';
import TextField from '@mui/material/TextField';
import { useModuleContext } from 'context/ModulesContext';
import { ChangeEvent, FC, FocusEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

interface EditableFieldProps {
  id: string;
  fieldName: string;
  currentValue: any;
  moduleName: string;
  relationModuleName?: string;
  type?: string;
  label?: string;
  renderLabel?: (value: any) => JSX.Element | string | number | null;
  beforeInit?: (value: any) => JSX.Element | string | number | null;
  beforeSave?: (value: any) => JSX.Element | string | number | null;
  onChange?: (value: string | number | object) => void;
}

const EditableField: FC<EditableFieldProps> = ({
  id,
  fieldName,
  currentValue,
  moduleName,
  type = 'text',
  relationModuleName,
  renderLabel,
  beforeSave,
  beforeInit,
  label,
  onChange,
}) => {
  const dispatch = useDispatch<any>();
  const isRelation: boolean = type === 'relation';
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const getLabel = (value: any) => {
    if (renderLabel) {
      return renderLabel(value);
    }

    if (isRelation) {
      return value?.relationFieldLabel;
    }

    return value;
  };

  const [value, setValue] = useState<any>(beforeInit && currentValue ? beforeInit(currentValue) : currentValue);
  const crudModule = useModuleContext(moduleName);

  if (!crudModule) {
    throw 'Module not found ' + moduleName;
  }

  useEffect(() => {
    setValue(beforeInit && currentValue ? beforeInit(currentValue) : currentValue);
  }, [currentValue]);

  const handleDoubleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleBlur = (newValue: any) => {
    setIsEditing(false);

    if (!newValue || newValue === 'Brak') {
      return;
    }

    switch (type) {
      case 'relation':
        newValue = { ...newValue };
        break;
      case 'number':
        newValue = parseFloat(newValue);
        break;
      default:
        newValue = newValue.toString();
    }

    newValue = beforeSave ? beforeSave(newValue) : newValue;

    if (onChange) {
      dispatch(
        addSingleToast({
          title: `Przygotowano zmiany`,
          config: { appearance: 'success' },
        }),
      );

      return onChange(newValue);
    }

    crudModule.api
      .put({ [fieldName]: newValue }, { id: id })
      .then(() =>
        dispatch(
          addSingleToast({
            title: `Zapisano zmiany`,
            config: { appearance: 'success' },
          }),
        ),
      )
      .catch(data => {
        dispatch(
          addSingleToast({
            title: data?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem',
            config: { appearance: 'error' },
          }),
        );
      });
  };

  return (
    <div onClick={handleDoubleClick}>
      {isEditing ? (
        <>
          {isRelation ? (
            <RelationSinglePicker
              moduleName={relationModuleName}
              value={value?.['@id']}
              onChange={(iri, id, obj) => {
                setValue(obj);
                handleBlur(obj);
              }}
              optionLabel={'relationFieldLabel'}
              autoFocus={true}
              label={label}
              className={''}
              name={'relationFieldLabel'}
            />
          ) : (
            <TextField
              type={type}
              value={value}
              label={label}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
              onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e.target.value)}
              autoFocus={true}
            />
          )}
        </>
      ) : (
        <span>{getLabel(value) ?? 'Brak'}</span>
      )}
    </div>
  );
};

export default EditableField;
